import initialState from '../initialState'

export default(state = initialState.dateSpecificSettings, action) => {
  switch (action.type) {
    case 'GET_DATE_SPECIFIC_SETTINGS_SUCCESS':
      return Object.assign({}, state, {
        dateSpecificSettings: action.dateSpecificSettings
      })
    default:
      return state
  }
}
