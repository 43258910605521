import initialState from '../initialState'

export default (state = initialState.stripeCustomer, action) => {
  switch (action.type) {
    case 'GET_STRIPE_CUSTOMER_SUCCESS':
      return Object.assign({}, state, {
        ...action.stripeCustomerData,
        fetched: true,
        fetchError: false
      })
    case 'GET_STRIPE_CUSTOMER_ERROR':
      return Object.assign({}, state, {
        fetched: true,
        fetchError: true
      })
    case 'GET_IS_BLOCKED_FROM_ADDING_BOOKINGS_SUCCESS':
      return Object.assign({}, state, {
        isBlockedFromAddingBookings: action.isBlockedFromAddingBookings,
        fetchError: false
      })
    case 'GET_IS_BLOCKED_FROM_ADDING_BOOKINGS_ERROR':
      return Object.assign({}, state, {
        fetchError: true
      })
    case 'SAVE_NEW_PAYMENT_METHOD_SUCCESS':
      return Object.assign({}, state, {
        ...action.stripeCustomerData,
        savingNewPaymentMethodLoading: false,
        savingNewPaymentMethodError: false
      })
    case 'SAVE_NEW_PAYMENT_METHOD_ERROR':
      return Object.assign({}, state, {
        savingNewPaymentMethodError: action.message,
        savingNewPaymentMethodLoading: false
      })
    case 'SAVE_NEW_PAYMENT_METHOD_LOADING':
      return Object.assign({}, state, {
        savingNewPaymentMethodLoading: true,
        savingNewPaymentMethodError: false
      })
    default:
      return state
  }
}
