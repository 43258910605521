export default {
  bookings: {
    bookings: null,
    recentlyCancelledBookings: null,
    recentlyCancelledBookingsLoading: false,
    recentlyCancelledBookingsError: false
  },
  dashboard: {
    loading: false,
    redirectToOnboarding: false
  },
  dateSpecificSettings: {
    dateSpecificSettings: []
  },
  products: {
    availableProducts: [],
    availablePlans: [],
    error: false,
    fetched: false,
    loading: false
  },
  restaurant: null,
  selectedDateInformation: {
    selectedDateBookingsBySession: [],
    selectedDateSessionSettings: [],
    selectedDateSpecificSettings: []
  },
  stripeCustomer: {
    fetched: false,
    fetchError: false,
    savingNewPaymentMethodError: false,
    savingNewPaymentMethodLoading: false
  },
  user: {
    authenticateError: false,
    isAuthenticated: null,
    authenticationLoading: false,
    signinError: false,
    signinErrorMessage: '',
    signoutError: false,
  }
}