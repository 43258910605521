import initialState from '../initialState'

export default(state = initialState.user, action) => {
  switch (action.type) {
    case 'AUTHENTICATE_SUCCESS':
      return Object.assign({}, state, {
        isAuthenticated: true,
        authenticateError: false,
        ...action.userData
      })
    case 'AUTHENTICATE_ERROR': 
      return Object.assign({}, state, {
        isAuthenticated: false,
        authenticateError: true
      })
    case 'AUTHENTICATE_LOADING_START':
      return Object.assign({}, state, {
        authenticationLoading: true,
      })
    case 'AUTHENTICATE_LOADING_END':
      return Object.assign({}, state, {
        authenticationLoading: false,
      })
    case 'SIGNIN_SUCCESS': 
      return Object.assign({}, state, {
        isAuthenticated: true,
        signinError: false,
        signinErrorMessage: '',
        ...action.userData
      })
    case 'SIGNIN_ERROR': 
      return Object.assign({}, state, {
        isAuthenticated: false,
        signinError: true
      })
    case 'SIGNIN_ERROR_MESSAGE':
      return Object.assign({}, state, {
        signinErrorMessage: action.message
      })
    case 'SIGNOUT_SUCCESS': 
      return Object.assign({}, state, {
        isAuthenticated: false,
        signoutError: false
      })
    case 'SIGNOUT_ERROR': 
      return Object.assign({}, state, {
        signoutError: true
      })
    default:
      return state
  }
}
