import initialState from '../initialState'

export default(state = initialState.restaurant, action) => {
  switch (action.type) {
    case 'GET_RESTAURANT_SUCCESS':
      return action.restaurant
    default:
      return state
  }
}
