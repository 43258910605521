import initialState from '../initialState'

export default(state = initialState.dashboard, action) => {
  switch (action.type) {
    case 'DASHBOARD_LOADING_START':
      return Object.assign({}, state, {
        loading: true
      })
    case 'DASHBOARD_LOADING_END':
      return Object.assign({}, state, {
        loading: false
      })
    case 'REDIRECT_TO_ONBOARDING': {
      return Object.assign({}, state, {
        redirectToOnboarding: action.redirectToOnboarding
      })
    }
    default:
      return state
  }
}
