import React, { Suspense, lazy } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import Loading from './components/shared/Loading'

// Redux setup
import { Provider } from 'react-redux'
import Store from './redux/store'
const StoreInstance = Store()

const BookingForm = lazy(() => import('./BookingForm'))
const CancelBooking = lazy(() => import('./CancelBooking'))
const Signup = lazy(() => import('./Signup'))
const Onboarding = lazy(() => import('./Onboarding'))
const NotFound = lazy(() => import('./components/NotFound'))
const EmailVerified = lazy(() => import('./components/signup/EmailVerified'))
const PasswordResetForm = lazy(() => import('./components/signin/PasswordResetForm'))
const NewPasswordForm = lazy(() => import('./components/signin/NewPasswordForm'))
const BookingManagement = lazy(() => import('./BookingManagement'))
const SigninForm = lazy(() => import('./components/signin/SigninForm'))
const PrivateRoute = lazy(() => import('./PrivateRoute'))

const Routes = () => (
  <Provider store={StoreInstance}>
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact from="/booking-widget/:restaurantId" component={BookingForm} />
          <PrivateRoute exact from="/signin" component={SigninForm} />
          <PrivateRoute exact from="/booking-management" component={BookingManagement} />
          <Route exact from="/booking-widget/:restaurantId/cancel" component={CancelBooking} />
          <Route exact from="/booking-widget/:restaurantId/confirmation" render={() => <BookingForm bookingStage='confirmation' />} />
          <Route exact from="/signup" component={Signup} />
          <PrivateRoute exact from="/onboarding" component={Onboarding} />
          <Route from="/email-verification" component={EmailVerified} />
          <Route exact from="/reset-password" component={PasswordResetForm} />
          <Route exact from="/set-new-password" component={NewPasswordForm} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  </Provider>
)

export default Routes;