import initialState from '../initialState'

export default (state = initialState.products, action) => {
  switch (action.type) {
    case 'PRODUCTS_LOADING_START':
      return Object.assign({}, state, {
        loading: true
      })
    case 'PRODUCTS_LOADING_END':
      return Object.assign({}, state, {
        loading: false
      })
    case 'UPDATE_AVAILABLE_PRODUCTS':
      return Object.assign({}, state, {
        availableProducts: action.availableProducts,
        availablePlans: action.availablePlans,
        fetched: true,
        error: false
      })
    case 'UPDATE_AVAILABLE_PRODUCTS_ERROR':
      return Object.assign({}, state, {
        error: true
      })
    default:
      return state
  }
}
