import initialState from '../initialState'

export default(state = initialState.bookings, action) => {
  switch (action.type) {
    case 'GET_BOOKINGS_SUCCESS':
      return Object.assign({}, state, {
        bookings: action.bookings
      })
    case 'GET_RECENTLY_CANCELLED_BOOKINGS_LOADING_START':
      return Object.assign({}, state, {
        recentlyCancelledBookingsLoading: true
      })
    case 'GET_RECENTLY_CANCELLED_BOOKINGS_LOADING_END':
      return Object.assign({}, state, {
        recentlyCancelledBookingsLoading: false
      })
    case 'GET_RECENTLY_CANCELLED_BOOKINGS_SUCCESS':
      return Object.assign({}, state, {
        recentlyCancelledBookings: action.recentlyCancelledBookings,
        recentlyCancelledBookingsError: false
      })
    case 'GET_RECENTLY_CANCELLED_BOOKINGS_ERROR':
      return Object.assign({}, state, {
        recentlyCancelledBookings: [],
        recentlyCancelledBookingsError: true
      })
    default:
      return state
  }
}
