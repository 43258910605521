import React from 'react'

const Loading = ({
  isCentered,
  confirmed
}) => (
  <div className={`pt-3 ${isCentered && 'text-center'}`}>
    <div className={`circle-loader ${confirmed && 'load-complete'}`}>
      {confirmed && <div className='checkmark draw'/>}
    </div>
  </div>
)

Loading.defaultProps = {
  isCentered: true
}

export default Loading
