import { combineReducers } from 'redux'

import bookings from './bookings'
import dashboard from './dashboard'
import dateSpecificSettings from './dateSpecificSettings'
import products from './products'
import restaurant from './restaurant'
import selectedDateInformation from './selectedDateInformation'
import stripeCustomer from './stripeCustomer'
import user from './user'

const rootReducer = combineReducers({
    bookings,
    dateSpecificSettings,
    dashboard,
    products,
    restaurant,
    selectedDateInformation,
    stripeCustomer,
    user
})
export default rootReducer