import initialState from '../initialState'

export default(state = initialState.selectedDateInformation, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_DATE_BOOKINGS_BY_SESSION':
      return Object.assign({}, state, {
        selectedDateBookingsBySession: action.selectedDateBookingsBySession
      })
    case 'UPDATE_SELECTED_DATE_SESSION_SETTINGS':
      return Object.assign({}, state, {
        selectedDateSessionSettings: action.selectedDateSessionSettings
      })
    case 'UPDATE_SELECTED_DATE_SPECIFIC_SETTINGS':
      return Object.assign({}, state, {
        selectedDateSpecificSettings: action.selectedDateSpecificSettings
      })
    default:
      return state
  }
}
